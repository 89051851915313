import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SharedService } from '../../shared';
import { FtBox } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-box-edit',
    templateUrl: './box-edit.component.html',
    styleUrls: ['./box-edit.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class BoxEditComponent implements OnInit {
	data = inject<FtBox>(MAT_DIALOG_DATA);
	private service = inject(SharedService);
	private fb = inject(FormBuilder);
	private dialogRef = inject<MatDialogRef<BoxEditComponent>>(MatDialogRef);

	form: FormGroup;

	constructor() {
		this.form = this.fb.group({
			id: 0,
			name: ['', Validators.required],
			description: '',
			deleted: false,
		});
	}

	ngOnInit() {
		if (this.data && this.data.id !== 0) {
			this.form.patchValue(this.data);
		}
	}

	saveData(data) {
		this.service.saveBox(data).subscribe(res => this.dialogRef.close(res));
	}
}
