import {forkJoin, of as observableOf} from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions} from '@angular/material/dialog';
import {deleteItemFromArray, SharedService} from '../../../shared';

import {SettingService} from '../../setting.service';
import {assign} from 'lodash';
import {StaffContractDTO, UserAccountDTO} from '../../../model';
import {BoxEditComponent} from '../../box-edit/box-edit.component';
import {TranslateModule} from '@ngx-translate/core';
import {AsyncPipe} from '@angular/common';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatIconButton, MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatToolbar} from '@angular/material/toolbar';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { noSpacesOnlyValidator } from '../../../utils';

function passwordMatcher(c: AbstractControl) {
    return c.get('password').value === c.get('confirmPassword').value
        ? null
        : {nomatch: true};
}

export function required(min: number, max: number): any {
    return Validators.compose([
        Validators.required,
        Validators.minLength(min),
        Validators.maxLength(max),
    ]);
}

@Component({
    selector: 'ft-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatSuffix,
        MatSlideToggle,
        ColorPickerModule,
        MatDialogActions,
        MatButton,
        AsyncPipe,
        TranslateModule,
        MatError
    ]
})
export class UserEditComponent implements OnInit {
    selectedUser: any;
    form: FormGroup;
    functions: any[];
    profiles: any[];

    exists = observableOf(false);
    color: string = '#E5E5E5';
    contracts: StaffContractDTO[];
    boxes = [];

    #shared = inject(SharedService);
    #setting = inject(SettingService);
    #dialog = inject(MatDialog);
    #fb = inject(FormBuilder);
    #dialogRef = inject(MatDialogRef<UserEditComponent>);
    #destroyRef = inject(DestroyRef);

    onChangeUserName() {
        this.form
            .get('username')
            .valueChanges.pipe(debounceTime(200), takeUntilDestroyed(this.#destroyRef))
            .subscribe(value => {
                if (value && !this.selectedUser.id)
                    this.exists = this.#setting.isUserExists(value);
            });
    }

    onSave(data: UserAccountDTO) {
        data.color = this.color;

        this.#setting
            .saveUser(data)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(res => this.#dialogRef.close(res));
    }



    ngOnInit() {
        this.#createForm();
        this.onChangeUserName();

        forkJoin([
            this.#shared.getBoxes(),
            this.#shared.getProfiles(),
            this.#shared.getAllStaffContracts(),
            this.#shared.getFunctions(),
        ]).pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(value => ([this.boxes, this.profiles, this.contracts, this.functions] = value))

        this.selectedUser.password = '';
        this.form.patchValue(this.selectedUser);

        this.color = this.selectedUser.color;
    }

    #createForm() {
        let user: any = assign(new UserAccountDTO(), {
            username: ['', [required(3, 12), noSpacesOnlyValidator()]],
            password: ['', required(3, 64)],
            confirmPassword: ['', required(3, 64)],
            firstName: ['', [required(3, 64), noSpacesOnlyValidator()]],
            lastName: ['', [required(3, 64), noSpacesOnlyValidator()]],
            userDescription: ['', Validators.maxLength(255)],
            functionId: [null, Validators.required],
            profileId: [null, Validators.required],
        });

        if (this.selectedUser.id) user = assign(user, {
            password: [''],
            confirmPassword: [''],
        })

        this.form = this.#fb.group(user, {validator: passwordMatcher});
    }


    createBox() {
        this.#dialog
            .open(BoxEditComponent)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(res => this.updateBox(res));
    }

    editBox(box: any) {
        this.#dialog
            .open(BoxEditComponent, {data: box})
            .afterClosed()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(res => {
                if (res) deleteItemFromArray(this.boxes, box);
                this.updateBox(res);
            });
    }

    updateBox(box: any) {
        if (box && box.id !== 0) {
            this.boxes.push(box);
            this.form.get('boxId').patchValue(box?.id);
        }
    }

    deleteBox(box: any) {
        box.deleted = true;
        this.#shared.saveBox(box).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(res => {
            if (res) {
                deleteItemFromArray(this.boxes, res);
                this.form.get('boxId').patchValue(null);
            }
        });
    }
}
