import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SettingService } from '../../setting.service';
import { assign } from 'lodash';
import { SharedService } from '../../../shared';
import { forkJoin, Observable } from 'rxjs';
import {
	BillingCodeDTO,
	PHARMACEUTICAL2,
	ProcedureCodeDTO,
	TemplateModelDTO,
} from '../../../model';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-procedure-code-edit',
    templateUrl: './procedure-code-edit.component.html',
    styleUrls: ['./procedure-code-edit.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        MatSelect,
        MatSlideToggle,
        MatDialogActions,
        MatButton,
        AsyncPipe,
        TranslateModule,
    ]
})
export class ProcedureCodeEditComponent implements OnInit {
	selectedItem = inject<ProcedureCodeDTO>(MAT_DIALOG_DATA);
	private fb = inject(FormBuilder);
	private dialogRef = inject<MatDialogRef<ProcedureCodeEditComponent>>(MatDialogRef);
	private sharedService = inject(SharedService);
	private settingService = inject(SettingService);

	form: FormGroup;
	templateModels: TemplateModelDTO[];
	billingCodes: BillingCodeDTO[];
	selected: number;
	modalities: any[];
	pharmaceuticals: any[];
	performingPhysicians: any[];
	reasonForExams: any[];
	templateModelCtrl = new FormControl('');
	billingCodeCtrl = new FormControl('');
	filteredModels: Observable<TemplateModelDTO[]>;
	filteredBillingCodes: Observable<BillingCodeDTO[]>;
	existingCode: boolean = false;

	constructor() {
		this.createForm();
	}

	onSave(procedureCode: ProcedureCodeDTO) {
		this.settingService
			.saveProcedureCode(procedureCode)
			.subscribe(res => this.dialogRef.close(res));
	}

	ngOnInit() {
		this.pharmaceuticals = PHARMACEUTICAL2;

		const datasets = 'performingPhysicians,reasonForExams';

		forkJoin([
			this.settingService.getTemplateModels(),
			this.sharedService.getModalities(),
			this.sharedService.getBillingCodes(),
			this.sharedService.getDatasets(datasets),
		]).subscribe(data => {
			[this.templateModels, this.modalities, this.billingCodes] = data;

			datasets.split(',').forEach(it => (this[it] = data[3][it]));

			this.form.patchValue(this.selectedItem);

			this.filteredModels = this.templateModelCtrl.valueChanges.pipe(
				startWith(''),
				map(value => this._filterTemplates(value))
			);

			this.templateModelCtrl.patchValue(
				this.selectedItem?.templateModelName
			);

			this.filteredBillingCodes = this.billingCodeCtrl.valueChanges.pipe(
				startWith(''),
				map(value => this._filterBillingCodes(value))
			);

			this.billingCodeCtrl.patchValue(this.selectedItem?.billingCode);
		});
	}

	templateModelChange(model: TemplateModelDTO) {
		this.form.get('templateModelId').patchValue(model.id);
	}

	billingCodeChange(code: BillingCodeDTO) {
		this.form.get('billingCodeId').patchValue(code.id);
	}

	private _filterTemplates(value: string): TemplateModelDTO[] {
		const filterValue = value ? value.toLowerCase() : '';

		return this.templateModels.filter(
			model => model.name.toLowerCase().indexOf(filterValue) === 0
		);
	}

	private _filterBillingCodes(value: string): BillingCodeDTO[] {
		const filterValue = value ? value.toLowerCase() : '';

		return this.billingCodes.filter(
			model => model.code.toLowerCase().indexOf(filterValue) === 0
		);
	}

	private createForm() {
		this.form = this.fb.group(
			assign(new ProcedureCodeDTO(), {
				code: ['', Validators.required],
				duration: ['', Validators.required],
				billingCodeId: ['', Validators.required],
				modalityId: [null, Validators.required],
			})
		);

		if (!this.selectedItem?.id)
			this.form
				.get('code')
				.valueChanges.pipe(debounceTime(400))
				.subscribe(code => this.checkExistence(code));
	}

	private checkExistence(code: string) {
		this.sharedService
			.checkProcedureCodeExistence(code)
			.subscribe(exists => (this.existingCode = exists));
	}
}
