import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {
    buildClosingDays,
    getCalendarViews,
    getDays,
    getMomentTime,
    getTimeSlots,
    patchClosingDays,
    SharedService,
} from '../../shared';
import {filter as _filter, find as _find, assign} from 'lodash';
import {SettingService} from '../setting.service';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import { GeneralSettingDTO, CalendarSettingDTO, PortalShareConfigDTO } from '../../model';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../app-config.service';
import {STATISTIC_VIEWS} from '../../utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import moment from 'moment';
import {MatButton} from '@angular/material/button';
import {MatRadioGroup, MatRadioButton} from '@angular/material/radio';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatButtonToggleGroup, MatButtonToggle} from '@angular/material/button-toggle';
import {MatOption, MatOptgroup} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {MatInput} from '@angular/material/input';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatToolbar} from '@angular/material/toolbar';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { MatCheckbox } from '@angular/material/checkbox';

const UI_LANGUAGES = [
    {key: 'en', value: 'English'},
    {key: 'fr', value: 'Français'},
    {key: 'nl', value: 'Dutch'},
];

@Component({
    selector: 'ft-general-setting',
    templateUrl: './general-setting.component.html',
    styleUrls: ['./general-setting.component.scss'],
    imports: [
        MatToolbar,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatButtonToggleGroup,
        MatButtonToggle,
        MatSlideToggle,
        MatOptgroup,
        MatRadioGroup,
        MatRadioButton,
        MatButton,
        TranslateModule,
        MatCheckbox,
    ]
})
export class GeneralSettingComponent implements OnInit {
    calendarSetting: CalendarSettingDTO = new CalendarSettingDTO();
    generalSetting: GeneralSettingDTO;
    portalConfig: PortalShareConfigDTO;

    portalSharingDestinations: Set<string> = new Set();

    days: any[];
    slots = [];
    views: any[];
    exams: Observable<any[]>;

    idGenerationModes = [
        {name: 'MANUAL', checked: true},
        {name: 'AUTOMATIC', checked: false},
    ];
    printingModes = [
        {name: 'CHROME', checked: true},
        {name: 'CUPS', checked: false},
    ];
    calFormGroup!: FormGroup;
    statisticViews = STATISTIC_VIEWS;
    languages = UI_LANGUAGES;


    #destroyRef = inject(DestroyRef);

    #shared = inject(SharedService);
    #setting = inject(SettingService);
    #translate = inject(TranslateService);
    #snack = inject(MatSnackBar);
    #appConfig = inject(AppConfigService);
    #fb = inject(FormBuilder);

    onSelectView(_: any) {
        const dv = this.calFormGroup.controls['defaultView'].get('id');
        this.calendarSetting.defaultView = this.views.find(
            v => v.id === dv.value
        ).key;
        this.updateTimes();
        this.saveCalendarSetting(this.calendarSetting);
    }

    onSelectSchedulerView(e: any) {
        this.generalSetting.schedulerDefaultView = e.value;
        this.updateTimes();
        this.saveGeneralSetting(this.generalSetting);
    }

    onSelectTime(_: any) {
        const slot = this.calFormGroup.value.minTimeSlot;

        this.calendarSetting.minTimeSlot = _find(this.slots, slot).value;

        this.updateTimes();

        this.saveCalendarSetting(this.calendarSetting);
    }

    private updateTimes(): void {
        this.calendarSetting.openingTime = getMomentTime(
            this.calFormGroup.value.openingTime,
            'YYYY-MM-DDTHH:mm:ss'
        );
        this.calendarSetting.closingTime = getMomentTime(
            this.calFormGroup.value.closingTime,
            'YYYY-MM-DDTHH:mm:ss'
        );
    }

    onSelectDay(ev: any) {
        this.calendarSetting.closingDays = _filter(
            buildClosingDays(this.days, ev),
            d => d.checked
        )
            .map(d => d.key)
            .join(';');
        this.updateTimes();
        this.saveCalendarSetting(this.calendarSetting);
    }

    saveCalendarSetting(calSetting: CalendarSettingDTO): void {
        this.#setting.saveCalendarSetting(calSetting).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();
        localStorage.setItem('calSetting', JSON.stringify(calSetting));
    }

    ngOnInit() {
        this.calFormGroup = this.#fb.group(
            assign(new CalendarSettingDTO(), {
                defaultView: this.#fb.group({id: 2}),
                minTimeSlot: this.#fb.group({id: 3}),
            })
        );

        this.slots = getTimeSlots();
        this.days = getDays(this.#appConfig.appLang);
        this.views = getCalendarViews(this.#appConfig.appLang);

        this.exams = this.#shared.getReasonForExams();
        this.generalSetting = this.#appConfig.generalSetting;

        this.getCalendarSetting(this.generalSetting.imagingCenterId);
        this.getGeneralSetting(this.generalSetting.imagingCenterId);
        this.getPortalConfig();
    }

    getCalendarSetting(centerId: number) {
        this.#setting.getCalendarSetting(centerId)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(calSetting => {
                this.calendarSetting = calSetting;
                this.calendarSetting.openingTime = moment(
                    calSetting.openingTime,
                    'YYYY-MM-DDTHH:mm:ss'
                ).format('HH:mm');
                this.calendarSetting.closingTime = moment(
                    calSetting.closingTime,
                    'YYYY-MM-DDTHH:mm:ss'
                ).format('HH:mm');

                this.calFormGroup.patchValue(this.calendarSetting);

                patchClosingDays(this.days, calSetting.closingDays);

                const view = this.views.find(
                    v => v.key === calSetting.defaultView
                );
                const slot = this.slots.find(
                    val => Number(val.value) === calSetting.minTimeSlot
                );

                this.calFormGroup.controls['defaultView'].patchValue(view);
                this.calFormGroup.controls['minTimeSlot'].patchValue(
                    slot ? slot : 20
                );
            });
    }

    getGeneralSetting(centerId: number) {

        this.#setting.getGeneralSetting(centerId)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(settings => {
                if (settings) this.generalSetting = settings;
            });
    }

    saveGeneralSetting(generalSetting: GeneralSettingDTO): void {
        generalSetting.logoutTimeout = Math.abs(generalSetting.logoutTimeout);

        this.#setting.saveGeneralSetting(generalSetting)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(ok => {
                if (ok)
                    this.#snack.open(
                        this.#translate.instant('changes_saved'),
                        '',
                        {duration: 2000}
                    );
            });
    }

    reload = () => location.reload();

    openQueue() {
        window.open('/ft-queue', '_blank');
    }

    private getPortalConfig(): void {
        this.#setting.getPortalConfig()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(value => {
                this.portalConfig = value;
                this.portalConfig.destination.split(',').forEach(v => this.portalSharingDestinations.add(v));
            });
    }

    savePortalConfig(portalConfig: PortalShareConfigDTO) {
        this.#setting.savePortalConfig(portalConfig).pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((value: PortalShareConfigDTO) => (this.portalConfig = value));
    }

    toggleDestination(destination: string) {
        if (this.portalSharingDestinations.has(destination)) this.portalSharingDestinations.delete(destination);
        else this.portalSharingDestinations.add(destination);

        this.portalConfig.destination = [...this.portalSharingDestinations].join(',');

        this.savePortalConfig(this.portalConfig);
    }
}
