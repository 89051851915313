import { Component, OnInit, inject } from '@angular/core';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingService } from '../setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImagingCenter } from '../../model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle, MatCardContent, MatCardActions } from '@angular/material/card';
import { Dir } from '@angular/cdk/bidi';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton, MatFabButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
import { MatDrawerContainer, MatDrawer, MatDrawerContent } from '@angular/material/sidenav';

@Component({
    selector: 'ft-imaging-center-setting',
    templateUrl: './imaging-center-setting.component.html',
    styleUrls: ['./imaging-center-setting.component.scss'],
    imports: [
        MatDrawerContainer,
        MatDrawer,
        MatToolbar,
        MatIconButton,
        MatIcon,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        Dir,
        MatButton,
        MatDrawerContent,
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatCardSubtitle,
        MatCardContent,
        MatCardActions,
        MatTooltip,
        MatFabButton,
        TranslateModule,
    ]
})
export class ImagingCenterSettingComponent implements OnInit {
	private fb = inject(FormBuilder);
	private sharedService = inject(SharedService);
	private snack = inject(MatSnackBar);
	private _dialog = inject(MatDialog);
	private settingService = inject(SettingService);
	private _translate = inject(TranslateService);

	form: FormGroup;
	imagingCenters!: any[];
	deletable: boolean = false;

	constructor() {
		this.form = this.fb.group(new ImagingCenter());
	}

	onSave(center: ImagingCenter) {
		this.settingService[
			center.id ? 'updateImagingCenter' : 'createImagingCenter'
		](center).subscribe(value => {
			if (value) {
				this.snack.open(this._translate.instant('CHANGES_SAVED'), '', {
					duration: 2000,
				});
				this.getCenters();
			}
		});
	}

	ngOnInit() {
		this.getCenters();
	}

	private getCenters() {
		this.sharedService.getImagingCenters().subscribe(centers => {
			this.imagingCenters = centers;
			this.deletable = centers.length > 1;
		});
	}

	editCenter(center: any) {
		this.form.patchValue(center);
	}

	duplicateCenter(center: ImagingCenter) {
		this.settingService
			.duplicateCenter(center.id)
			.subscribe((duplicatedCenter: any) => {
				this.form.patchValue(duplicatedCenter);
			});
	}

	deleteCenter(center: any) {
		this._dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(confirmed => {
				if (confirmed)
					this.settingService
						.deleteImagingCenter(center.id)
						.subscribe(ok => {
							if (ok) {
								this.snack.open(
									this._translate.instant('DELETED'),
									'',
									{ duration: 2000 }
								);
								this.getCenters();
							}
						});
			});
	}
}
