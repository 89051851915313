import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { BillingCodeDTO } from '../../../model';
import { SettingService } from '../../setting.service';
import { MatDialogRef } from '@angular/material/dialog';
import { assign } from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-billing-code-edit',
    templateUrl: './billing-code-edit.component.html',
    styleUrls: ['./billing-code-edit.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class BillingCodeEditComponent implements OnInit {
	billingCode = inject<BillingCodeDTO>(MAT_DIALOG_DATA);
	private setting = inject(SettingService);
	private fb = inject(FormBuilder);
	private dialogRef = inject<MatDialogRef<BillingCodeEditComponent>>(MatDialogRef);

	billingCodeForm: FormGroup;

	constructor() {
		this.billingCodeForm = this.fb.group(
			assign(new BillingCodeDTO(), { code: ['', Validators.required] })
		);
	}

	ngOnInit() {
		if (this.billingCode) this.billingCodeForm.patchValue(this.billingCode);
	}

	saveBillingCode(code) {
		this.setting.saveBillingCode(code).subscribe(res => {
			this.dialogRef.close(res);
		});
	}
}
