import { BehaviorSubject, merge, of as observableOf } from "rxjs";

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnDestroy,
  TemplateRef,
  viewChild
} from "@angular/core";
import { SettingService } from "../setting.service";
import { ProcedureCodeEditComponent } from "./procedure-code-edit/procedure-code-edit.component";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { MatTableDataSource, MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from "@angular/material/table";
import { DeleteConfirmComponent, SharedService } from "../../shared";
import { rowsAnimation } from "../../animations";
import FileSaver from "file-saver";
import { DEFAULT_SEARCH_CONFIG, ProcedureCodeDTO } from "../../model";
import { PROCEDURE_CODE_TABLE_CONF } from "./table-conf";
import { union } from "lodash";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchService } from "../../shared/advanced-search/search.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateModule } from "@ngx-translate/core";
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from "@angular/cdk/table";
import { MatIcon } from "@angular/material/icon";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatToolbar } from "@angular/material/toolbar";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
    selector: "ft-procedure-code-setting",
    templateUrl: "./procedure-code-setting.component.html",
    styleUrls: ["./procedure-code-setting.component.scss"],
    animations: [rowsAnimation],
    imports: [
        MatProgressSpinner,
        MatToolbar,
        MatButton,
        MatIcon,
        FormsModule,
        ReactiveFormsModule,
        MatTable,
        MatSort,
        CdkColumnDef,
        CdkHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        CdkCellDef,
        MatCell,
        MatIconButton,
        CdkHeaderRowDef,
        MatHeaderRow,
        CdkRowDef,
        MatRow,
        MatPaginator,
        TranslateModule,
    ]
})
export class ProcedureCodeSettingComponent implements AfterViewInit {
  private service = inject(SettingService);
  private sharedService = inject(SharedService);
  private _searchService = inject(SearchService);
  dialog = inject(MatDialog);
  private _fb = inject(FormBuilder);

  cols: any[];
  displayedColumns = [];

  dataSource = new MatTableDataSource<ProcedureCodeDTO>();

  readonly fileInput = viewChild<ElementRef>("fileInput");
  readonly sort = viewChild(MatSort);
  readonly paginator = viewChild(MatPaginator);

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  filterChange = new BehaviorSubject("");
  filterForm: FormGroup;
  downloading: boolean;
  public uploadDone: boolean;
  public uploading: boolean = false;
  trackById = (_: number, item: any): string => item.id;

  searchTemplate = viewChild.required<TemplateRef<any>>("searchTemplate");

  #destroyRef = inject(DestroyRef);

  constructor() {
    this.filterForm = this._fb.group({ key: new FormControl("") });

    this.#destroyRef.onDestroy(() =>
      this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG)
    );

    setTimeout(() => {
      this._searchService.searchInputConfig.set({
        placeholder: "search.procedure",
        expandable: false,
        hidden: false,
        template: this.searchTemplate(),
      });

      this._searchService.genericSearchObs.subscribe((value) =>
        this.filterForm.get("key").patchValue(value)
      );
    });

    const columns = PROCEDURE_CODE_TABLE_CONF.filter((it) => it.label !== "id");
    this.displayedColumns = columns;
    this.cols = union(
      columns.map((it) => it.label),
      ["action"]
    );
  }

  editProcedureCode(code?: ProcedureCodeDTO) {
    this.dialog
      .open(ProcedureCodeEditComponent, {
        data: code,
        disableClose: true,
        width: "45vw",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) this.filterChange.next("");
      });
  }

  deleteProcedureCode(code: ProcedureCodeDTO) {
    this.dialog
      .open(DeleteConfirmComponent)
      .afterClosed()
      .subscribe((ok) => {
        if (ok) {
          this.service.deleteProcedureCode(code.id).subscribe((res) => {
            this.filterChange.next("");
          });
        }
      });
  }

  deleteAll() {
    this.dialog
      .open(DeleteConfirmComponent)
      .afterClosed()
      .subscribe((ok) => {
        if (ok) {
          this.service.deleteAllProcedureCodes().subscribe((res) => {
            this.filterChange.next("");
          });
        }
      });
  }

  uploadFile() {
    this.fileInput().nativeElement.click();
  }

  ngAfterViewInit() {
    this.filterForm
      .get("key")
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((value) => {
        if (!this.dataSource) return;
        this.paginator().pageIndex = 0;
        this.filterChange.next(value);
      });

    this.sort().sortChange.subscribe(() => (this.paginator().pageIndex = 0));

    const observedFilters = [
      this.sort().sortChange.asObservable(),
      this.paginator().page.asObservable(),
      this.filterChange.asObservable(),
    ];

    merge(...observedFilters)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sharedService.queryProcedureCodes(
            this.paginator().pageSize,
            this.paginator().pageIndex,
            this.sort().active,
            this.sort().direction,
            this.filterChange.getValue()
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data["totalElements"];

          return data["content"] as ProcedureCodeDTO[];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  exportProcedures() {
    this.downloading = true;

    this.sharedService.exportProcedures().subscribe({
      next: (data) => {
        this.downloading = false;

        const blob = new Blob([data], {
          type: "application/vnd.ms-excel",
        });
        const file = new File([blob], "procedures.xlsx", {
          type: "application/vnd.ms-excel",
        });

        FileSaver.saveAs(file);
      },
      error: (_) => (this.downloading = false),
    });
  }

  onFileChange(event: any) {
    this.uploading = true;
    this.uploadDone = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.service.uploadProceduresFile(file).subscribe({
        next: (value) => console.log(value),
        complete: () => {
          this.uploadDone = true;
          this.uploading = false;
          this.filterChange.next("");
        },
      });
    }
  }

  protected readonly ProcedureCodeDTO = ProcedureCodeDTO;
}
