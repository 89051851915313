<mat-toolbar class="ftx-dialog-toolbar" color="primary">
    <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
    <h3 class="text-lg font-semibold pl-1">
        {{ "EDIT_USER" | translate }}
    </h3>
    <span class="fx-grow-1"></span>
    <button mat-icon-button matDialogClose="" tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div matDialogContent>
    <div [formGroup]="form">
        <div class="fx-layout-row-nowrap fx-gap-4">
            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "LASTNAME" | translate }}</mat-label>
                <input
                        matInput
                        placeholder="{{ 'LASTNAME' | translate }}"
                        formControlName="lastName"
                        required
                        autocomplete="off"
                />
            </mat-form-field>

            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "FIRSTNAME" | translate }}</mat-label>
                <input
                        matInput
                        placeholder="{{ 'FIRSTNAME' | translate }}"
                        formControlName="firstName"
                        required
                        autocomplete="off"
                />
            </mat-form-field>

            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "STAFF_CONTRACT" | translate }}</mat-label>
                <mat-select
                        placeholder="{{ 'STAFF_CONTRACT' | translate }}"
                        formControlName="contractId"
                        class="full-width"
                >
                    @for (contract of contracts; track $index) {
                        <mat-option [value]="contract.id" class="option">
                            {{ contract.name }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "BOX" | translate }}</mat-label>
                <mat-select
                        placeholder="{{ 'BOX' | translate }}"
                        formControlName="boxId"
                        class="fx-fill-width"
                >
                    @for (box of boxes; track $index) {
                        <mat-option [value]="box.id" class="option">
                            <div class="fx-layout-row-nowrap">
                                <span>{{ box.name }}</span>
                                <span class="fx-grow-1"></span>
                                <button
                                        mat-icon-button
                                        (click)="$event.stopImmediatePropagation(); editBox(box)"
                                >
                                    <mat-icon
                                            fontSet="mdi"
                                            fontIcon="mdi-pencil"
                                            [style.color]="'#454545'"
                                    ></mat-icon>
                                </button>
                                <button
                                        mat-icon-button
                                        (click)="$event.stopImmediatePropagation(); deleteBox(box)"
                                >
                                    <mat-icon
                                            fontSet="mdi"
                                            fontIcon="mdi-delete"
                                            [style.color]="'#883946'"
                                    ></mat-icon>
                                </button>
                            </div>
                        </mat-option>
                    }
                </mat-select>
                <button
                        mat-icon-button
                        matIconSuffix
                        (click)="$event.stopImmediatePropagation(); createBox()"
                >
                    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="fx-layout-row-nowrap fx-gap-4">
            <div class="fx-grow-1">
                <mat-form-field class="fx-fill-width">
                    <mat-label>{{ "USERNAME" | translate }}</mat-label>
                    <input
                            matInput
                            autocomplete="off"
                            placeholder="{{ 'USERNAME' | translate }}"
                            formControlName="username"
                            required
                    />
                </mat-form-field>
            </div>

            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "PASSWORD" | translate }}</mat-label>
                <input
                        matInput
                        autocomplete="off"
                        placeholder="{{ 'PASSWORD' | translate }}"
                        formControlName="password"
                        type="password"
						[required]="!selectedUser.id"
                />
            </mat-form-field>

            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "CONFIRMPASSWORD" | translate }}</mat-label>
                <input
                        matInput
                        placeholder="{{ 'CONFIRMPASSWORD' | translate }}"
                        formControlName="confirmPassword"
                        type="password"
						[required]="!selectedUser.id"
                />
            </mat-form-field>
        </div>

        <div class="fx-layout-row-nowrap fx-gap-4">
            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "CLINICALID" | translate }}</mat-label>
                <input
                        matInput
                        placeholder="{{ 'CLINICALID' | translate }}"
                        formControlName="clinicalID"
                />
            </mat-form-field>

            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "PROFILE" | translate }}</mat-label>
                <mat-select
                        placeholder="{{ 'PROFILE' | translate }}"
                        formControlName="profileId"
                        class="full-width"
                        [required]="true"
                >
                    @for (fn of profiles; track $index) {
                        <mat-option [value]="fn.id" class="option">
                            {{ fn.name }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field class="fx-grow-1">
                <mat-label>{{ "FUNCTION" | translate }}</mat-label>
                <mat-select
                        placeholder="{{ 'FUNCTION' | translate }}"
                        formControlName="functionId"
                        [required]="true"
                >
                    @for (fn of functions; track $index) {
                        <mat-option [value]="fn.id" class="option">
                            {{ fn.description | translate }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field class="flex-grow w-full">
            <mat-label>{{ "user.description" | translate }}</mat-label>
            <textarea [rows]="2"
                      matInput
                      placeholder="{{ 'user.description' | translate }}"
                      formControlName="userDescription"
            ></textarea>
            <mat-error>Max length 255</mat-error>
        </mat-form-field>


        <div
                class="space-top fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
        >
            <mat-slide-toggle
                    class="fx-grow-1"
                    formControlName="canViewConfidentialData"
                    color="accent"
            >
                {{ "CAN_VIEW_CONFIDENTIAL_DATA" | translate }}
            </mat-slide-toggle>

            <div class="ft-color">
                <div>{{ "COLOR" | translate }}:</div>
                <div
                        class="color-area"
                        [(colorPicker)]="color"
                        [style.background]="color"
                ></div>
            </div>
        </div>
    </div>
</div>

<div matDialogActions align="end">
    @if (exists | async) {
        <span class="ft-error">{{ "USERNAME_EXIST" | translate }}</span>
    }
    <span class="fx-grow-1"></span>
    <button color="warn" matDialogClose="" mat-raised-button>
        {{ "CANCEL" | translate }}
    </button>
    <button
            (click)="onSave(form.value)"
            [disabled]="form.invalid || (exists | async)"
            color="primary"
            mat-raised-button
    >
        {{ "SAVE" | translate }}
    </button>
</div>
