import {
  AfterViewInit,
  Component,
  inject, OnDestroy,
  OnInit, TemplateRef, viewChild
} from "@angular/core";
import { SettingService } from "../setting.service";
import { DeleteConfirmComponent, SharedService } from "../../shared";
import { UserEditComponent } from "./user-edit/user-edit.component";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";

import { union } from "lodash";
import { USER_TABLE_CONF } from "./table-conf";
import { MatTableDataSource, MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from "@angular/material/table";
import {DEFAULT_SEARCH_CONFIG, UserAccountDTO} from "../../model";
import {
  catchError,
  debounceTime,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { merge, of as observableOf } from "rxjs";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {SearchService} from "../../shared/advanced-search/search.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgStyle } from "@angular/common";
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from "@angular/cdk/table";
import { MatIcon } from "@angular/material/icon";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatToolbar } from "@angular/material/toolbar";

@Component({
    selector: "ft-users-setting",
    templateUrl: "./users-setting.component.html",
    styleUrls: ["./users-setting.component.scss"],
    imports: [
        MatToolbar,
        MatButton,
        MatIcon,
        FormsModule,
        ReactiveFormsModule,
        MatTable,
        MatSort,
        CdkColumnDef,
        CdkHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        CdkCellDef,
        MatCell,
        NgStyle,
        MatIconButton,
        CdkHeaderRowDef,
        MatHeaderRow,
        CdkRowDef,
        MatRow,
        MatPaginator,
        TranslateModule,
    ]
})
export class UsersSettingComponent implements OnInit, AfterViewInit, OnDestroy {
  private _searchService = inject(SearchService);
  private _fb = inject(FormBuilder);

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }
  cols: any[];
  displayedColumns = [];

  private profiles: any[] = [];

  public dataSource = new MatTableDataSource<UserAccountDTO>();
  readonly sort = viewChild(MatSort);
  readonly paginator = viewChild(MatPaginator);

  private settingService = inject(SettingService);
  private sharedService = inject(SharedService);
  private dialog = inject(MatDialog);

  public filterGroup: FormGroup;
  private _filterForm: FormGroup;

  searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');

  constructor() {
    this.filterGroup = this._fb.group({key:''});

    setTimeout(() => {
      this._searchService.searchInputConfig.set({
        placeholder: 'search.users',
        expandable:false,
        hidden:false,
        template: this.searchTemplate()
      });

      this._searchService.genericSearchObs.subscribe(value => this.filterGroup.get('key').patchValue(value));
    });
  }

  ngOnDestroy() {
    this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
  }

  ngOnInit() {
    this.sharedService
      .getShortProfiles()
      .subscribe((data) => (this.profiles = data));

    this.displayedColumns = USER_TABLE_CONF;
    this.cols = union(
      USER_TABLE_CONF.filter((it) => !it.hidden).map((it) => it.value),
      ["action"]
    );
  }

  ngAfterViewInit() {
    const sort = this.sort();
    sort?.sortChange.subscribe((_) => (this.paginator().pageIndex = 0));

    const observedFilters = [
      sort?.sortChange.asObservable(),
      this.paginator()?.page.asObservable(),
      this.filterGroup.valueChanges.pipe(debounceTime(250)),
    ];

    merge(...observedFilters)
      .pipe(
        startWith({}),
        switchMap(() => {
          const query = this.filterGroup.get('key').value;

          return this.sharedService.getAllUsers(
            this.paginator().pageSize,
            this.paginator().pageIndex,
            this.sort().active,
            this.sort().direction,
            query
          );
        }),
        map((data) => data["content"] as UserAccountDTO[]),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  editUser(user: UserAccountDTO = new UserAccountDTO()) {
    const dialogRef = this.dialog.open(UserEditComponent);
    const instance = dialogRef.componentInstance;

    instance.selectedUser = user;

    dialogRef.afterClosed().subscribe((res) => {
      if (res) this.filterGroup.get('key').patchValue("");
    });
  }

  deleteUser(user: UserAccountDTO) {
    this.dialog
      .open(DeleteConfirmComponent)
      .afterClosed()
      .subscribe((ok) => {
        if (ok) {
          this.settingService.deleteUser(user).subscribe((_) => {});
        }
      });
  }

  public profileName(id: number): string {
    return this.profiles.find((it) => it.id === id)?.name ?? "-";
  }

  public functionName(id: number): string {
    const funcions = {
      130: "REFERRING_PHYSICIAN",
      131: "PERFORMING_PHYSICIAN",
      132: "ADMINISTRATOR",
      133: "TECHNICIAN",
      134: "ASSISTANT",
    };
    return funcions[id] ?? "-";
  }
}
