import { Component, inject } from '@angular/core';
import { SettingService } from '../setting.service';
import {DEFAULT_SEARCH_CONFIG, PrintingTemplateModel} from '../../model';
import {SearchService} from "../../shared/advanced-search/search.service";
import { TranslateModule } from '@ngx-translate/core';
import { ReporterComponent } from '../../shared/reporter/reporter.component';
import { MatIcon } from '@angular/material/icon';
import { MatNavList, MatListItem, MatListItemIcon, MatListItemTitle } from '@angular/material/list';

@Component({
    selector: 'ft-printing-models',
    templateUrl: './printing-models.component.html',
    styleUrls: ['./printing-models.component.scss'],
    imports: [
        MatNavList,
        MatListItem,
        MatIcon,
        MatListItemIcon,
        MatListItemTitle,
        ReporterComponent,
        TranslateModule,
    ]
})
export class PrintingModelsComponent {
	private setting = inject(SettingService);

	printingFiles: PrintingTemplateModel[] = [];
	selectedFile: any;

	constructor() {
		this.setting.getPrintingModels().subscribe(data => {
			this.printingFiles = data;
			this.selectedFile = data.find(it => it.name === 'welcome');
		});
	}

	openFile(file: any) {
		this.selectedFile = file;
	}

	editorReady($event: string) {}
}
