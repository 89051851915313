import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialogClose,
	MatDialogContent,
	MatDialogActions,
} from '@angular/material/dialog';
import { SettingService } from '../../setting.service';
import { SharedService } from '../../../shared';
import { assign } from 'lodash';
import { AetFullDTO } from '../../../model';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { forkJoin } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ft-aet-edit',
    templateUrl: './aet-edit.component.html',
    styleUrls: ['./aet-edit.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        ColorPickerModule,
        MatSlideToggle,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class AetEditComponent implements OnInit {
	form: FormGroup;

	technicians: any[];
	modalities: any[];
	rooms: any[] = [];

	color: string = '#e1e1e1';

	public data = inject(MAT_DIALOG_DATA);
	#dialogRef = inject(MatDialogRef<AetEditComponent>);
	#setting = inject(SettingService);
	#shared = inject(SharedService);
	#fb = inject(FormBuilder);
	#destroyRef = inject(DestroyRef);

	constructor() {
		this.form = this.#fb.group(
			assign(new AetFullDTO(), {
				modalityId: ['', Validators.required],
				defaultTechnicianId: ['', Validators.required],
				room: ['', Validators.required],
			}),
		);

		if (this.data)
			this.#setting
				.getAet(this.data)
				.subscribe(aet => {
					this.color = aet.color;
					this.form.patchValue(aet);
				});
	}

	onSave(aet: AetFullDTO) {
		aet.color = this.color;
		this.#setting.saveAet(aet)
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe((value: any) => {
				if (value) {
					value._room = value.room.name;
					this.#dialogRef.close(value);
				}
			});
	}

	ngOnInit() {
		forkJoin([
			this.#shared.getTechnicians(),
			this.#shared.getModalities(),
			this.#shared.getRooms(),
		]).pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe(data => ([this.technicians, this.modalities, this.rooms] = data));
	}
}
