import { Component, inject, OnInit } from '@angular/core';
import {DEFAULT_SEARCH_CONFIG, DefaultValues} from '../../model';
import { SettingService } from '../setting.service';
import { forkJoin } from 'rxjs';
import { SharedService } from '../../shared';
import {SearchService} from "../../shared/advanced-search/search.service";
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-default-values',
    templateUrl: './default-values.component.html',
    styleUrls: ['./default-values.component.scss'],
    imports: [
        MatToolbar,
        MatFormField,
        MatLabel,
        MatSelect,
        FormsModule,
        MatOption,
        MatIconButton,
        MatSuffix,
        MatIcon,
        MatInput,
        TranslateModule,
    ]
})
export class DefaultValuesComponent implements OnInit {
	defaultValues: DefaultValues;

	titles: any[] = [];
	maritalStatuses: any[] = [];
	genders: any[] = [];
	paymentMethods: any[] = [];
	priorities: any[] = [];
	physicians: any[] = [];
	technicians: any[] = [];
	patientClasses: any[] = [];
	confidentialities: any[] = [];

	private _setting = inject(SettingService);
	private _shared = inject(SharedService);

	ngOnInit(): void {
		forkJoin([
			this._shared.getTitles(),
			this._shared.getMaritalStatuses(),
			this._shared.getGenders(),
			this._shared.getPaymentMethods(),
			this._shared.getPriorities(),
			this._shared.getPerformingPhysicians(),
			this._shared.getTechnicians(),
			this._shared.getPatientClasses(),
			this._shared.getConfidentialities(),
		]).subscribe(data => {
			[
				this.titles,
				this.maritalStatuses,
				this.genders,
				this.paymentMethods,
				this.priorities,
				this.physicians,
				this.technicians,
				this.patientClasses,
				this.confidentialities,
			] = data;

			this._setting
				.getDefaultValues()
				.subscribe(value => (this.defaultValues = value));
		});
	}

	saveDefaultValues(defaultValues: DefaultValues) {
		this._setting.saveDefaultValues(defaultValues).subscribe();
	}

	clearInput(key: string) {
		this.defaultValues[key] = undefined;
		this.saveDefaultValues(this.defaultValues);
	}
}
