import { Component, inject, isDevMode, OnInit, Renderer2 } from '@angular/core';
import moment from 'moment';
import { AppConfigService } from './app-config.service';
import { AutoLogoutService } from './auto-logout.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'ft-root',
    template: ` <router-outlet></router-outlet>`,
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
	#config: AppConfigService = inject(AppConfigService);
	#renderer: Renderer2 = inject(Renderer2);
	#autoLogoutService = inject(AutoLogoutService);

	ngOnInit(): void {
		moment.locale(this.#config.appLang);
		this.loadScript('/web-apps/apps/api/documents/api.js');

		localStorage.removeItem('last_filter_wf');
		localStorage.removeItem('last_filter_sc');

		this.#autoLogoutService.startMonitoring();
	}

	private loadScript(scriptUrl: string): void {
		if (document.getElementById('o_office')) return;
		if (isDevMode()) {
			const { hostname, protocol } = location;
			scriptUrl = `${protocol}//${hostname}:1500${scriptUrl}`;
		}
		const script = this.#renderer.createElement('script');
		script.type = 'text/javascript';
		script.src = scriptUrl;
		script.id = 'o_office';

		script.onload = () => {
			console.log(
				'%cFireOffice script loaded successfully.',
				'color: green;font-weight:bold;'
			);
		};

		script.onerror = () => {
			console.error(
				'%cError loading FireOffice script.',
				'color:red;font-weight:bold'
			);
		};

		this.#renderer.appendChild(document.head, script);
	}
}
