import { Component, inject } from '@angular/core';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SettingService } from '../setting.service';
import {DEFAULT_SEARCH_CONFIG, ReasonForExamDTO} from '../../model';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SearchService} from "../../shared/advanced-search/search.service";
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton, MatFabButton } from '@angular/material/button';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-reason-for-exam',
    templateUrl: './reason-for-exam.component.html',
    styleUrls: ['./reason-for-exam.component.scss'],
    imports: [
        MatToolbar,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatChipListbox,
        MatChipOption,
        MatExpansionPanelDescription,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatRadioGroup,
        MatRadioButton,
        ColorPickerModule,
        MatButton,
        MatFabButton,
        MatTooltip,
        MatIcon,
        TranslateModule,
    ]
})
export class ReasonForExamComponent {
	private _settingService = inject(SettingService);
	private _sharedService = inject(SharedService);
	private _fb = inject(FormBuilder);
	private _dialog = inject(MatDialog);
	private _snack = inject(MatSnackBar);

	reasonForExams: ReasonForExamDTO[] = [];
	reasonForExamForm!: FormGroup;
	currentReasonForExamColor: string = '#32c5da';

	constructor() {
		this.reasonForExamForm = this._fb.group(new ReasonForExamDTO());
		this.getReasonForExams();
	}

	deleteReasonForExam(reasonForExam: ReasonForExamDTO) {
		this._dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this._settingService
						.deleteReasonForExam(reasonForExam.id)
						.subscribe(res => {
							if (res) {
								this._snack.open(
									'Ligne supprimé avec succès!',
									'',
									{ duration: 1500 }
								);
								this.getReasonForExams();
							} else {
								this._snack.open(
									'Sorry! Item already used !',
									'Ok',
									{ duration: 2000 }
								);
							}
						});
				}
			});
	}

	selectReasonForExam(
		reasonForExam: ReasonForExamDTO = new ReasonForExamDTO()
	) {
		this.currentReasonForExamColor = reasonForExam.color;
		this.reasonForExamForm.patchValue(reasonForExam);
	}

	saveRFE(reasonForExam: ReasonForExamDTO) {
		reasonForExam.color = this.currentReasonForExamColor;
		this._sharedService.saveReasonForExam(reasonForExam).subscribe(_ => {
			this._snack.open('Saved successfully !', '', { duration: 2000 });
			this.getReasonForExams();
		});
	}

	private getReasonForExams() {
		this._sharedService
			.getReasonForExams()
			.subscribe(data => (this.reasonForExams = data));
	}

	addReasonForExam() {
		this.reasonForExams.push(new ReasonForExamDTO());
	}
}
